import Box from '@mui/material/Box';
import { Icon } from '@iconify/react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Box
          borderRadius="lg"
          textAlign="center"
          >
            <p className="hello">Hi, I'm Ivan 👋</p>
        </Box>
        <Box sx={{ width: '50%' }}>
          <Box className="textbox">
            I am a Go developer before I learned Rust, Python and C/C++.
            <br/>
            <br/>
            I have experience in DevOps: Docker, k8s, GitHub(Action)/GitLab(CI/CD).
            <br/>
            <br/>
            I also have experience with databases PostgreSQL, MySQL, InfluxDB, Cassandra, MongoDB.
            Proficient in bash and nix for automation.
            <br/>
            <br/>
            For frontend, I have been learning ReactTS (Material-UI and TailwindCSS) and Svelte.
            <br/>
            <br/>
            Also I try to participate in the Open Source community. I am proficient in Linux and use it confidently in my daily routine.
          </Box>
          <hr/>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }} className="textbox">
            <a href='https://github.com/g0dm0d/yogurt'>Minecraft launcher</a>
            <a href='https://github.com/g0dm0d/small-todo'>Tiny todo cli app</a>
            <a href='https://github.com/g0dm0d/uptime'>Status page service</a>
            <a href='https://github.com/g0dm0d/vrcapi'>VRCapi sdk</a>
          </Box>
          <hr/>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box component="div" sx={{ display: 'inline' }}>
              <a href="https://github.com/g0dm0d">
                <Icon color="white" icon="akar-icons:github-fill" />
              </a>
            </Box>
            <Box component="div" sx={{ display: 'inline' }}>
              <a href="https://discordapp.com/users/279311290688602112">
                <Icon color="white" icon="bxl:discord-alt" />
              </a>
            </Box>
            <Box component="div" sx={{ display: 'inline' }}>
              <a href="mailto:contact@godmod.dev?subject=Hello">
                <Icon color="white" icon="clarity:email-solid" />
              </a>
            </Box>
            <Box component="div" sx={{ display: 'inline' }}>
              <a href="https://gitlab.com/g0dm0d">
                <Icon color="white" icon="ri:gitlab-fill" />
              </a>
            </Box>
          </Box>
        </Box>
      </header>
    </div>
  );
}

export default App;
